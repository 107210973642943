<script setup lang="ts">
interface Props {
  outside?: () => void
  open: boolean
}

const props = defineProps<Props>()

const slots = useSlots()

useHead({
  bodyAttrs: {
    class: computed(() => props.open ? 'overflow-hidden' : ''),
  },
})
</script>

<template>
  <Teleport
    v-if="props.open"
    to="body"
  >
    <div
      class="bg-black-100/[.32] h-lvh w-full fixed py-12 top-0 bottom-0 z-40 overflow-x-hidden overflow-y-scroll"
      @click="props.outside"
    >
      <aside
        :class="[
          'flex flex-col gap-6 shadow-large-gray border border-black-50 rounded-lg bg-white-100 px-12 py-10 absolute right-0 left-0 top-0 bottom-0 z-40 sm:w-100 h-fit mx-auto my-auto',
          $attrs.class,
        ]"
        @click.stop
      >
        <h4
          v-if="slots.header"
          class="text-h4 text-black-100 -mb-3 break-words"
        >
          <slot name="header" />
        </h4>
        <p
          v-if="slots.body"
          class="text-body-regular text-black-75 break-words"
        >
          <slot name="body" />
        </p>
        <div v-if="slots.default">
          <slot />
        </div>
        <div
          v-if="slots.footer"
          class="grid grid-flow-col gap-2"
        >
          <slot name="footer" />
        </div>
      </aside>
    </div>
  </Teleport>
</template>

<style scoped lang="postcss">

</style>
