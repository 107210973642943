import { default as _91id_935MagCck69eMeta } from "/usr/prnews/pages/campaign/edit/[id].vue?macro=true";
import { default as index76B3BooqUlMeta } from "/usr/prnews/pages/campaign/index.vue?macro=true";
import { default as _91id_93vVUQnhcl8PMeta } from "/usr/prnews/pages/cart/[id].vue?macro=true";
import { default as indexOetAk2wHssMeta } from "/usr/prnews/pages/cart/index.vue?macro=true";
import { default as confirmCKZIosRUyUMeta } from "/usr/prnews/pages/confirm.vue?macro=true";
import { default as indextixPADgAKXMeta } from "/usr/prnews/pages/dashboard/[organizationId]/[projectId]/[campaignId]/index.vue?macro=true";
import { default as indexK7redOgmzmMeta } from "/usr/prnews/pages/dashboard/[organizationId]/index.vue?macro=true";
import { default as indexV2rLt3rmvgMeta } from "/usr/prnews/pages/dashboard/index.vue?macro=true";
import { default as forgotT0jU0YpYYNMeta } from "/usr/prnews/pages/forgot.vue?macro=true";
import { default as createTT6cJ0V6u7Meta } from "/usr/prnews/pages/format/create.vue?macro=true";
import { default as index6cc7yeNr8YMeta } from "/usr/prnews/pages/index.vue?macro=true";
import { default as _91id_93Zw6GEkjnanMeta } from "/usr/prnews/pages/media/[id].vue?macro=true";
import { default as billingyiic22wU5bMeta } from "/usr/prnews/pages/profile/billing.vue?macro=true";
import { default as generalH0ohJjEUsMMeta } from "/usr/prnews/pages/profile/general.vue?macro=true";
import { default as notificationsGROgmIXmzVMeta } from "/usr/prnews/pages/profile/notifications.vue?macro=true";
import { default as settingsBxlbfuUy18Meta } from "/usr/prnews/pages/profile/settings.vue?macro=true";
import { default as campaignbrluYEmmQBMeta } from "/usr/prnews/pages/project/[id]/campaign.vue?macro=true";
import { default as indexXMRDLZySv0Meta } from "/usr/prnews/pages/project/[id]/index.vue?macro=true";
import { default as create0LGSMYMI6KMeta } from "/usr/prnews/pages/project/create.vue?macro=true";
import { default as dashboard9ncki3kSDuMeta } from "/usr/prnews/pages/publisher/dashboard.vue?macro=true";
import { default as index8oIsIhW7FNMeta } from "/usr/prnews/pages/publisher/index.vue?macro=true";
import { default as kycpwXVnwGnTkMeta } from "/usr/prnews/pages/publisher/kyc.vue?macro=true";
import { default as setupgmTUImp6I8Meta } from "/usr/prnews/pages/publisher/setup.vue?macro=true";
import { default as resetvGpJtpi4ovMeta } from "/usr/prnews/pages/reset.vue?macro=true";
import { default as sign_45in6DHLljCWbrMeta } from "/usr/prnews/pages/sign-in.vue?macro=true";
import { default as sign_45upcn4yN9uMRTMeta } from "/usr/prnews/pages/sign-up.vue?macro=true";
import { default as surveyyOs57ViaxKMeta } from "/usr/prnews/pages/survey.vue?macro=true";
import { default as _91id_93Vk3tpyPGWwMeta } from "/usr/prnews/pages/top-up/[id].vue?macro=true";
import { default as indexwtKIPfwPIlMeta } from "/usr/prnews/pages/top-up/index.vue?macro=true";
export default [
  {
    name: "campaign-edit-id___en-US",
    path: "/campaign/edit/:id()",
    component: () => import("/usr/prnews/pages/campaign/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "campaign-edit-id___fr-FR",
    path: "/fr-FR/campaign/edit/:id()",
    component: () => import("/usr/prnews/pages/campaign/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "campaign___en-US",
    path: "/campaign",
    component: () => import("/usr/prnews/pages/campaign/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign___fr-FR",
    path: "/fr-FR/campaign",
    component: () => import("/usr/prnews/pages/campaign/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-id___en-US",
    path: "/cart/:id()",
    component: () => import("/usr/prnews/pages/cart/[id].vue").then(m => m.default || m)
  },
  {
    name: "cart-id___fr-FR",
    path: "/fr-FR/cart/:id()",
    component: () => import("/usr/prnews/pages/cart/[id].vue").then(m => m.default || m)
  },
  {
    name: "cart___en-US",
    path: "/cart",
    meta: indexOetAk2wHssMeta || {},
    component: () => import("/usr/prnews/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart___fr-FR",
    path: "/fr-FR/cart",
    meta: indexOetAk2wHssMeta || {},
    component: () => import("/usr/prnews/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "confirm___en-US",
    path: "/confirm",
    component: () => import("/usr/prnews/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: "confirm___fr-FR",
    path: "/fr-FR/confirm",
    component: () => import("/usr/prnews/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizationId-projectId-campaignId___en-US",
    path: "/dashboard/:organizationId()/:projectId()/:campaignId()",
    meta: indextixPADgAKXMeta || {},
    component: () => import("/usr/prnews/pages/dashboard/[organizationId]/[projectId]/[campaignId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizationId-projectId-campaignId___fr-FR",
    path: "/fr-FR/dashboard/:organizationId()/:projectId()/:campaignId()",
    meta: indextixPADgAKXMeta || {},
    component: () => import("/usr/prnews/pages/dashboard/[organizationId]/[projectId]/[campaignId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizationId___en-US",
    path: "/dashboard/:organizationId()",
    meta: indexK7redOgmzmMeta || {},
    component: () => import("/usr/prnews/pages/dashboard/[organizationId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizationId___fr-FR",
    path: "/fr-FR/dashboard/:organizationId()",
    meta: indexK7redOgmzmMeta || {},
    component: () => import("/usr/prnews/pages/dashboard/[organizationId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___en-US",
    path: "/dashboard",
    meta: indexV2rLt3rmvgMeta || {},
    component: () => import("/usr/prnews/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___fr-FR",
    path: "/fr-FR/dashboard",
    meta: indexV2rLt3rmvgMeta || {},
    component: () => import("/usr/prnews/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot___en-US",
    path: "/forgot",
    meta: forgotT0jU0YpYYNMeta || {},
    component: () => import("/usr/prnews/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: "forgot___fr-FR",
    path: "/fr-FR/forgot",
    meta: forgotT0jU0YpYYNMeta || {},
    component: () => import("/usr/prnews/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: "format-create___en-US",
    path: "/format/create",
    meta: createTT6cJ0V6u7Meta || {},
    component: () => import("/usr/prnews/pages/format/create.vue").then(m => m.default || m)
  },
  {
    name: "format-create___fr-FR",
    path: "/fr-FR/format/create",
    meta: createTT6cJ0V6u7Meta || {},
    component: () => import("/usr/prnews/pages/format/create.vue").then(m => m.default || m)
  },
  {
    name: "index___en-US",
    path: "/",
    meta: index6cc7yeNr8YMeta || {},
    component: () => import("/usr/prnews/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr-FR",
    path: "/fr-FR",
    meta: index6cc7yeNr8YMeta || {},
    component: () => import("/usr/prnews/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "media-id___en-US",
    path: "/media/:id()",
    meta: _91id_93Zw6GEkjnanMeta || {},
    component: () => import("/usr/prnews/pages/media/[id].vue").then(m => m.default || m)
  },
  {
    name: "media-id___fr-FR",
    path: "/fr-FR/media/:id()",
    meta: _91id_93Zw6GEkjnanMeta || {},
    component: () => import("/usr/prnews/pages/media/[id].vue").then(m => m.default || m)
  },
  {
    name: "profile-billing___en-US",
    path: "/profile/billing",
    meta: billingyiic22wU5bMeta || {},
    component: () => import("/usr/prnews/pages/profile/billing.vue").then(m => m.default || m)
  },
  {
    name: "profile-billing___fr-FR",
    path: "/fr-FR/profile/billing",
    meta: billingyiic22wU5bMeta || {},
    component: () => import("/usr/prnews/pages/profile/billing.vue").then(m => m.default || m)
  },
  {
    name: "profile-general___en-US",
    path: "/profile/general",
    meta: generalH0ohJjEUsMMeta || {},
    component: () => import("/usr/prnews/pages/profile/general.vue").then(m => m.default || m)
  },
  {
    name: "profile-general___fr-FR",
    path: "/fr-FR/profile/general",
    meta: generalH0ohJjEUsMMeta || {},
    component: () => import("/usr/prnews/pages/profile/general.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications___en-US",
    path: "/profile/notifications",
    meta: notificationsGROgmIXmzVMeta || {},
    component: () => import("/usr/prnews/pages/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications___fr-FR",
    path: "/fr-FR/profile/notifications",
    meta: notificationsGROgmIXmzVMeta || {},
    component: () => import("/usr/prnews/pages/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings___en-US",
    path: "/profile/settings",
    meta: settingsBxlbfuUy18Meta || {},
    component: () => import("/usr/prnews/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings___fr-FR",
    path: "/fr-FR/profile/settings",
    meta: settingsBxlbfuUy18Meta || {},
    component: () => import("/usr/prnews/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "project-id-campaign___en-US",
    path: "/project/:id()/campaign",
    component: () => import("/usr/prnews/pages/project/[id]/campaign.vue").then(m => m.default || m)
  },
  {
    name: "project-id-campaign___fr-FR",
    path: "/fr-FR/project/:id()/campaign",
    component: () => import("/usr/prnews/pages/project/[id]/campaign.vue").then(m => m.default || m)
  },
  {
    name: "project-id___en-US",
    path: "/project/:id()",
    meta: indexXMRDLZySv0Meta || {},
    component: () => import("/usr/prnews/pages/project/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "project-id___fr-FR",
    path: "/fr-FR/project/:id()",
    meta: indexXMRDLZySv0Meta || {},
    component: () => import("/usr/prnews/pages/project/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "project-create___en-US",
    path: "/project/create",
    component: () => import("/usr/prnews/pages/project/create.vue").then(m => m.default || m)
  },
  {
    name: "project-create___fr-FR",
    path: "/fr-FR/project/create",
    component: () => import("/usr/prnews/pages/project/create.vue").then(m => m.default || m)
  },
  {
    name: "publisher-dashboard___en-US",
    path: "/publisher/dashboard",
    meta: dashboard9ncki3kSDuMeta || {},
    component: () => import("/usr/prnews/pages/publisher/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "publisher-dashboard___fr-FR",
    path: "/fr-FR/publisher/dashboard",
    meta: dashboard9ncki3kSDuMeta || {},
    component: () => import("/usr/prnews/pages/publisher/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "publisher___en-US",
    path: "/publisher",
    meta: index8oIsIhW7FNMeta || {},
    component: () => import("/usr/prnews/pages/publisher/index.vue").then(m => m.default || m)
  },
  {
    name: "publisher___fr-FR",
    path: "/fr-FR/publisher",
    meta: index8oIsIhW7FNMeta || {},
    component: () => import("/usr/prnews/pages/publisher/index.vue").then(m => m.default || m)
  },
  {
    name: "publisher-kyc___en-US",
    path: "/publisher/kyc",
    meta: kycpwXVnwGnTkMeta || {},
    component: () => import("/usr/prnews/pages/publisher/kyc.vue").then(m => m.default || m)
  },
  {
    name: "publisher-kyc___fr-FR",
    path: "/fr-FR/publisher/kyc",
    meta: kycpwXVnwGnTkMeta || {},
    component: () => import("/usr/prnews/pages/publisher/kyc.vue").then(m => m.default || m)
  },
  {
    name: "publisher-setup___en-US",
    path: "/publisher/setup",
    meta: setupgmTUImp6I8Meta || {},
    component: () => import("/usr/prnews/pages/publisher/setup.vue").then(m => m.default || m)
  },
  {
    name: "publisher-setup___fr-FR",
    path: "/fr-FR/publisher/setup",
    meta: setupgmTUImp6I8Meta || {},
    component: () => import("/usr/prnews/pages/publisher/setup.vue").then(m => m.default || m)
  },
  {
    name: "reset___en-US",
    path: "/reset",
    meta: resetvGpJtpi4ovMeta || {},
    component: () => import("/usr/prnews/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: "reset___fr-FR",
    path: "/fr-FR/reset",
    meta: resetvGpJtpi4ovMeta || {},
    component: () => import("/usr/prnews/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: "sign-in___en-US",
    path: "/sign-in",
    meta: sign_45in6DHLljCWbrMeta || {},
    component: () => import("/usr/prnews/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "sign-in___fr-FR",
    path: "/fr-FR/sign-in",
    meta: sign_45in6DHLljCWbrMeta || {},
    component: () => import("/usr/prnews/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "sign-up___en-US",
    path: "/sign-up",
    meta: sign_45upcn4yN9uMRTMeta || {},
    component: () => import("/usr/prnews/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "sign-up___fr-FR",
    path: "/fr-FR/sign-up",
    meta: sign_45upcn4yN9uMRTMeta || {},
    component: () => import("/usr/prnews/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "survey___en-US",
    path: "/survey",
    meta: surveyyOs57ViaxKMeta || {},
    component: () => import("/usr/prnews/pages/survey.vue").then(m => m.default || m)
  },
  {
    name: "survey___fr-FR",
    path: "/fr-FR/survey",
    meta: surveyyOs57ViaxKMeta || {},
    component: () => import("/usr/prnews/pages/survey.vue").then(m => m.default || m)
  },
  {
    name: "top-up-id___en-US",
    path: "/top-up/:id()",
    component: () => import("/usr/prnews/pages/top-up/[id].vue").then(m => m.default || m)
  },
  {
    name: "top-up-id___fr-FR",
    path: "/fr-FR/top-up/:id()",
    component: () => import("/usr/prnews/pages/top-up/[id].vue").then(m => m.default || m)
  },
  {
    name: "top-up___en-US",
    path: "/top-up",
    meta: indexwtKIPfwPIlMeta || {},
    component: () => import("/usr/prnews/pages/top-up/index.vue").then(m => m.default || m)
  },
  {
    name: "top-up___fr-FR",
    path: "/fr-FR/top-up",
    meta: indexwtKIPfwPIlMeta || {},
    component: () => import("/usr/prnews/pages/top-up/index.vue").then(m => m.default || m)
  }
]